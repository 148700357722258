import React from 'react'
import { VARIABLES } from '../../Constant';
import Login from '../../Auth/Login';
import secureLocalStorage from 'react-secure-storage';

const Header = () => {
  return (
    <>
      <div className="nk-header">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <a href={VARIABLES.career_redirection} className="logo-link">
              <img className="logo-light logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo" />
              <img className="logo-dark logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo-dark" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
              </svg>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item d-flex align-center"> <a className="nav-link nav-link-icon" href={VARIABLES.career_redirection} > <i className="fas fa-home"></i> <span className="nav-link-inner--text d-lg-none">Home</span> </a> </li>
                <li className="nav-item ms-2">
                  <button
                    className="btn btn-sm btn-info"
                    type="button"
                    href="javascript:void(0)"
                    data-bs-target="#loginModal"
                    data-bs-toggle="modal"
                    id="button-button-signinmodal"
                    onClick={() => {
                      secureLocalStorage.setItem("login_status", 'login')
                    }}
                  >
                    Login
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Header;