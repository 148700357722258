// Content.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import JobOpening from "../Pages/JobOpening";
import PageOpening from "../Pages/PageOpening";
import Login from "../../Auth/Login";
import SignUp from "../../Auth/SignUp";
import VerifyEmail from "../../Auth/VerifyEmail";
import ResetPassword from "../Pages/forgot-password/ResetPassword";

const Content = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<JobOpening />} />
        <Route path={`/:title`} element={<PageOpening />} />
        <Route path={`/reset-password/confirm/:id/:id`} element={<ResetPassword />} />
      </Routes>

      <Login loginHandler={props.loginHandler} />
      <SignUp loginHandler={props.loginHandler} />
      <VerifyEmail />
    </>
  );
};

export default Content;
