import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const CandidateDetailsContext = createContext(null);

function CandidateDetailsProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  //Personal Data
  const [isCandidatePersonalDataLoading, setIsCandidatePersonalDataLoading] =
    useState(true);
  const [candidatePersonalDetails, setCandidatePersonalDetails] = useState([]);
  console.log(candidatePersonalDetails);

  //Educational Data
  const [isCandidateEducationDataLoading, setIsCandidateEducationDataLoading] =
    useState(true);
  const [candidateEducationDetails, setCandidateEducationDetails] = useState(
    []
  );

  //Professional Data
  const [
    isCandidateProfessionalDataLoading,
    setIsCandidateProfessionalDataLoading,
  ] = useState(true);
  const [candidateprofessionalData, setCandidateProfessionalData] = useState(
    []
  );

  //Additional Data
  const [
    isCandidateAdditionalDataLoading,
    setIsCandidateAdditionalDataLoading,
  ] = useState(true);
  const [candidateAdditionalData, setCandidateAdditionalData] = useState([]);

  //Personal Details of candidate
  const getCandidatePersonalData = async (candidate_job_id, access) => {
    let url =
      VARIABLES.url + `/api/userdetails/?candidate_job=${candidate_job_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsCandidatePersonalDataLoading(true);
      const res = await axios(config);
      setCandidatePersonalDetails(res.data.data);
      setIsCandidatePersonalDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidatePersonalDataLoading(true);
      } else {
        setIsCandidatePersonalDataLoading(false);
      }
    }
  };

  //Educational Details of candidate
  const getCandidateEducationData = async (candidate_job_id, access) => {
    let url =
      VARIABLES.url +
      `/api/career-educationdetails/?candidate_job=${candidate_job_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsCandidateEducationDataLoading(true);
      const res = await axios(config);
      setCandidateEducationDetails(res.data.data);
      setIsCandidateEducationDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateEducationDataLoading(true);
      } else {
        setIsCandidateEducationDataLoading(false);
      }
    }
  };

  //Professional Details of candidate
  const getCandidateProfessionalData = async (candidate_job_id, access) => {
    let url =
      VARIABLES.url +
      `/api/career-professionaldetails/?candidate_job=${candidate_job_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsCandidateProfessionalDataLoading(true);
      const res = await axios(config);
      setCandidateProfessionalData(res.data.data);
      setIsCandidateProfessionalDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateProfessionalDataLoading(true);
      } else {
        setIsCandidateProfessionalDataLoading(false);
      }
    }
  };

  //Additional Details of candidate
  const getCandidateAdditionalData = async (candidate_job_id, requisition_id, access) => {
    let url =
      VARIABLES.url + `/api/candidatejob/?candidate_job=${candidate_job_id}&requisition_id=${requisition_id}`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      setIsCandidateAdditionalDataLoading(true);
      const res = await axios(config);
      setCandidateAdditionalData(res.data.data);
      setIsCandidateAdditionalDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateAdditionalDataLoading(true);
      } else {
        setIsCandidateAdditionalDataLoading(false);
      }
    }
  };

  return (
    <div>
      <CandidateDetailsContext.Provider
        value={{
          candidateEducationDetails,
          isCandidateEducationDataLoading,
          getCandidateEducationData,

          isCandidateProfessionalDataLoading,
          getCandidateProfessionalData,
          candidateprofessionalData,

          candidatePersonalDetails,
          isCandidatePersonalDataLoading,
          getCandidatePersonalData,

          candidateAdditionalData,
          isCandidateAdditionalDataLoading,
          getCandidateAdditionalData,
        }}
      >
        {children}
      </CandidateDetailsContext.Provider>
    </div>
  );
}

export default CandidateDetailsProvider;
