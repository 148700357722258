import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../../Constant";
import { TypeMasterContext } from "../../../Context/TypeMaster";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";

const PersonalInformation = (props) => {
  const { gender } = useContext(TypeMasterContext);
  const { userDetails, getUserDetailsData, candidateData, getProfessionalDetails } = useContext(UserDetailsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      gender: { value: "", label: "" }
    }
  });

  const userID = secureLocalStorage.getItem("userID");
  const candidate_id = secureLocalStorage.getItem("candidate_info");
  const role = secureLocalStorage.getItem("role_data");

  const editedJobPost = candidateData.personal_details

  const TodayDate = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(TodayDate.getFullYear() - 14);
  const maxValidDate = maxDate.toISOString().split("T")[0];

  const [isEditable, setIsEditable] = useState(
    userDetails === null || userDetails?.length === 0
  );

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      props.handleNext();
    }
  };

  const onSubmit = (data) => {
    const role_id = role.length > 0 ? role.map((rl) => rl.value) : 8;
    let user = new FormData();
    user.append("role_id", role_id);
    user.append("login_type_id", VARIABLES.career_login);
    user.append("first_name", data?.first_name);
    user.append("last_name", data?.last_name);
    user.append("email", data?.email_id);
    user.append("mobile", data?.mobile);
    user.append(
      "attachment",
      userDetails?.[0]?.attachment
        ? userDetails?.[0]?.attachment
        : data?.attachment
    );
    user.append("skills", data?.skills);
    user.append("current_location", data?.current_location);
    user.append("date_of_birth", data?.date_of_birth);
    user.append("gender", data?.gender.value);
    user.append("linkedin_url", data?.linkedin_url);
    user.append("facebook_url", data?.facebook_url);
    user.append("instagram_url", data?.instagram_url);
    user.append("twitter_url", data?.twitter_url);
    user.append("candidate_to_be_process_id", candidate_id);
    user.append("updated_by_id", userID);

    const url =
      VARIABLES.url + "/api/userdetails/" + userID + "/?send_from=career";
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        secureLocalStorage.setItem(
          "resume_id",
          response.data.data[0].candidate_resume_id
        );
        Swal.fire({
          icon: "success",
          title: "You have succesfully updated your personal details"
        }).then((result) => {
          if (result.isConfirmed) {
            props.handleNext();
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        Swal.fire({
          icon: "cross",
          title: "Failed to update personal details"
        })
      });
  };

  useEffect(() => {
    getProfessionalDetails()
  }, [])

  useEffect(() => {
    if (editedJobPost) {
      let phoneNumber = editedJobPost?.mobile;
      if (phoneNumber?.startsWith("+91")) {
        phoneNumber = phoneNumber.slice(3);
      }
      var defaultValues = {};
      defaultValues.first_name = editedJobPost?.first_name;
      defaultValues.last_name = editedJobPost?.last_name;
      defaultValues.email_id = editedJobPost?.email;
      defaultValues.mobile = phoneNumber;
      defaultValues.current_location = editedJobPost?.current_location;
      defaultValues.skills = editedJobPost?.skills === "null" ? "" : editedJobPost?.skills;
      defaultValues.linkedin_url = editedJobPost.linkedin_url === "null" ? "" : editedJobPost.linkedin_url;
      defaultValues.facebook_url = editedJobPost.facebook_url === "null" ? "" : editedJobPost.linkedin_url;
      defaultValues.instagram_url =
        editedJobPost.instagram_url === "null"
          ? ""
          : editedJobPost.linkedin_url;
      defaultValues.twitter_url =
        editedJobPost.twitter_url === "null" ? "" : editedJobPost.linkedin_url;
      defaultValues.date_of_birth = editedJobPost?.date_of_birth;
      if (editedJobPost?.gender) {
        const getGender = {
          value: editedJobPost?.gender,
          label: editedJobPost?.gender,
        };
        defaultValues.gender = getGender;
      }
      reset(defaultValues);
    }
  }, [editedJobPost]);

  useEffect(() => {
    getUserDetailsData(userID);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    First name <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("first_name", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="text-forms-first_name"
                    type="text"
                    placeholder="Enter your first name"
                    disabled
                  />
                  {errors.first_name && (
                    <span className="fs-8 text-danger">
                      First name is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Last name <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("last_name", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="text-forms-last_name"
                    type="text"
                    placeholder="Enter your last name"
                    disabled
                  />
                  {errors.last_name && (
                    <span className="fs-8 text-danger">
                      Last name is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Email <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("email_id", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="text-forms-email_id"
                    type="text"
                    placeholder="Enter your email"
                    disabled />
                  {errors.email_id && (
                    <span className="fs-8 text-danger">Email is required</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Mobile Number <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("mobile", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                      validate: {
                        startsWithValidDigit: (value) =>
                          ["9", "8", "7", "6"].includes(value.toString().charAt(0)) || "First digit should be 9, 8, 7, or 6",
                      },
                    })}
                    defaultValue=""
                    className="form-control"
                    id="number-forms-mobile"
                    type="number"
                    placeholder="Enter mobile number here..."
                    onWheel={(e) => e.target.blur()}
                    disabled
                  />
                  {errors.mobile && errors.mobile.type === "required" && (
                    <span className="fs-8 text-danger">
                      Mobile number is required
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type === "minLength" && (
                    <span className="fs-8 text-danger">
                      Mobile number should be at least 10 digits
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type === "maxLength" && (
                    <span className="fs-8 text-danger">
                      Mobile number should not exceed 10 digits
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type === "startsWithValidDigit" && (
                    <span className="fs-8 text-danger">
                      {errors.mobile.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Date Of Birth <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("date_of_birth", {
                      required: true,
                    })}
                    defaultValue=""
                    className="form-control"
                    id="date-form-date_of_birth"
                    type="date"
                    placeholder="Enter your date of birth..."
                    max={maxValidDate}
                    disabled={!!editedJobPost?.date_of_birth && !isEditable}
                  />
                  {errors.date_of_birth &&
                    errors.date_of_birth.type === "required" && (
                      <span className="fs-8 text-danger">
                        Date of birth is required
                      </span>
                    )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  {editedJobPost?.attachment ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Resume</label>
                        <div className="preview">
                          <a
                            href={editedJobPost.attachment}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Preview
                          </a>
                        </div>
                      </div>
                      <Controller
                        name="attachment"
                        control={control}
                        defaultValue=""
                        rules={{
                          validate: (value) =>
                            !value || value.type === "application/pdf",
                        }}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-forms-attachments"
                            type="file"
                            onChange={(e) => field.onChange(e.target.files[0])}
                            disabled={!!editedJobPost?.attachment && !isEditable}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <label className="form-label">
                        Resume <span className="fs-8 text-danger">*</span>
                      </label>
                      <Controller
                        name="attachment"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          validate: (value) =>
                            value?.type === "application/pdf",
                        }}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-forms-attachments"
                            type="file"
                            onChange={(e) => field.onChange(e.target.files[0])}
                          />
                        )}
                      />
                      {errors.attachment &&
                        errors.attachment.type === "required" && (
                          <span className="fs-8 text-danger">
                            Resume is required
                          </span>
                        )}
                      {errors.attachment &&
                        errors.attachment.type === "validate" && (
                          <span className="fs-8 text-danger">
                            Please upload your document in pdf format
                          </span>
                        )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    {" "}
                    Current Location <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("current_location", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="text-forms-current_location"
                    type="text"
                    placeholder="Enter your current location"
                    disabled={editedJobPost?.current_location && !isEditable}
                  />
                  {errors.current_location && (
                    <span className="fs-8 text-danger">
                      Current location is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Gender <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    name="gender"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={gender}
                        id="select-forms-gender"
                        isSearchable={true}
                        isClearable={true}
                        defaultValue=""
                        isDisabled={editedJobPost?.gender && !isEditable}
                      />
                    )}
                  />
                  {errors.gender && (
                    <span className="fs-8 text-danger">Gender is required</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Skills</label>
                  <input
                    {...register("skills")}
                    defaultValue=""
                    type="text"
                    className="form-control"
                    id="textarea-forms-skills"
                    placeholder="Enter your skills here..."
                    disabled={editedJobPost?.skills && !isEditable}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Linkedin url</label>
                  <input
                    {...register("linkedin_url")}
                    defaultValue=""
                    className="form-control"
                    id="url-forms-linkedin_url"
                    type="url"
                    placeholder="Enter your linkedin url here..."
                    disabled={!!editedJobPost?.linkedin_url && !isEditable}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Facebook url</label>
                  <input
                    {...register("facebook_url")}
                    defaultValue=""
                    className="form-control"
                    id="url-forms-facebook_url"
                    type="url"
                    placeholder="Enter your facebook url here..."
                    disabled={!!editedJobPost?.facebook_url && !isEditable}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Instagram url</label>
                  <input
                    {...register("instagram_url")}
                    defaultValue=""
                    className="form-control"
                    id="url-forms-instagram_url"
                    type="url"
                    placeholder="Enter your instagram url here..."
                    disabled={!!editedJobPost?.instagram_url && !isEditable}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">Twitter url</label>
                  <input
                    {...register("twitter_url")}
                    defaultValue=""
                    className="form-control"
                    id="url-forms-twitter_url"
                    type="url"
                    placeholder="Enter your twitter url here..."
                    disabled={!!editedJobPost?.twitter_url && !isEditable}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {userDetails && userDetails?.length !== 0 && (
                  <>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={toggleEdit}
                      >
                        {isEditable ? "Cancel" : "Edit"}<em className="icon ni ni-pen2 ms-1"></em>
                      </button>
                    </div>
                  </>
                )}
                {isLoading ? (
                  <button className="btn text-dark" disabled>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="ms-2 btn btn-primary"
                    id="submit-button-personalinformation"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalInformation;
