export const demo = {
  "total_progress": 75,
  "form": [
    {
      "name": "HR Round",
      "stage": 3,
      "status": "Completed"
    },
    {
      "name": "Technical Round",
      "stage": 3,
      "status": "Completed"
    },
    {
      "name": "Assignment",
      "stage": 1,
      "status": "Scheduled"
    },
    {
      "name": "Mettl Test",
      "stage": 0,
      "status": "Future Scheduled"
    },
    {
      "name": "SHF",
      "stage": 0,
      "status": "Future Scheduled"
    },
    {
      "name": "Know Your Candidate",
      "stage": 0,
      "status": "Future Scheduled"
    },
    {
      "name": "Offer Letter",
      "stage": 0,
      "status": "Future Scheduled"
    }
  ]
};
