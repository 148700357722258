import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { VARIABLES } from "../Constant";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const KYCContext = createContext(null);

function KYCContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const [KYCDetails, setKYCDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getKYCDetails = async (candidate_job_id, access) => {
    setIsLoading(true);
    let url;
    url =
      VARIABLES.url +
      `/api/knowyourcandidate/?candidate_job_id=${candidate_job_id}&send_from=career`;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setKYCDetails(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  };

  const value = {
    KYCDetails,
    isLoading,
    getKYCDetails,
  };
  return (
    <div>
      <KYCContext.Provider value={value}>{children}</KYCContext.Provider>
    </div>
  );
}

export default KYCContextProvider;
