import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";
import { DashboardContext } from "../../Context/DashboardContext";

const WelcomeMessage = () => {
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  console.log("first_name: ", first_name);
  console.log("last_name: ", last_name);

  const navigate = useNavigate();

  const {
    notifications,
    unreadNotificationsCount,
    handleNotificationStatus,
    updateNotificationStatus,
    setUnreadNotificationsCount,
    retryCount,
    getNotifications,
    getAllNotifications,
  } = useContext(NotificationsContext);

  const { rawPendingOnYou, getPendingOnYouData, isPendingOnYouLoading } =
    useContext(DashboardContext);

  const handleNavigation = () => {
    console.log("first");
    navigate("/pendingonyou");
  };

  useEffect(() => {
    setUnreadNotificationsCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    console.log(retryCount);
    if (retryCount !== 0) {
      // enqueueSnackbar("No connection!", {
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "center",
      //   },
      //   variant: "error",
      //   persist: true,
      //   preventDuplicate: true,
      // });
    } else {
      // closeSnackbar();
    }
  }, [retryCount]);

  useEffect(() => {
    getNotifications();
    getAllNotifications();
    getPendingOnYouData(userID);
  }, []);

  return (
    <>
      <div className="nk-block-between ps-5 pe-5">
        <div className="nk-block-head-content w-100 d-flex align-items-center justify-content-between">
          <div className="group">
            <h3 className="nk-block-title page-title">
              {`Hello, ${
                first_name
                  ? first_name[0].toUpperCase() + first_name.slice(1)
                  : ""
              } ${
                last_name ? last_name[0].toUpperCase() + last_name.slice(1) : ""
              }!`}
            </h3>
            <p className="py-0">Check your daily tasks & Schedules</p>
          </div>

          <div className="d-flex">
            {/* {privileges.includes("pending_on_you") ? ( */}
            <div class="drodown me-3">
              <a
                href="#"
                class="dropdown-toggle btn btn-primary"
                data-bs-toggle="dropdown"
              >
                <span>Pending On You</span>
                <em class="dd-indc icon ni ni-chevron-right"></em>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <ul
                  class="link-list-opt no-bdr"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {!isPendingOnYouLoading ? (
                    rawPendingOnYou?.length !== 0 ? (
                      rawPendingOnYou?.map((api) => {
                        return (
                          <li className="d-flex align-items-center pe-3">
                            <a className="dropdown-item" href="#">
                              {api?.statistics_type}
                            </a>
                            <span className="badge bg-outline-gray">
                              {api?.message}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <>
                        <li className="d-flex align-items-center pe-3">
                          <span className="dropdown-item">No Data</span>
                        </li>
                      </>
                    )
                  ) : (
                    <>
                      <li className="d-flex align-items-center pe-3">
                        <span className="dropdown-item">Loading...</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/* ) : (
                ""
              )} */}
            {/* {privileges.includes("new_ticket") ? ( */}
            <div class="dropdown notification-dropdown">
              <div
                type="button"
                className="dropdown-toggle btn btn-primary btn-sm position-relative"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                data-bs-auto-close="outside"
                style={{ fontSize: "1em" }}
              >
                <span>Notifications</span>
                {unreadNotificationsCount !== 0 && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {unreadNotificationsCount <= 99
                      ? unreadNotificationsCount.toString()
                      : "99+"}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </div>
              <div
                class="dropdown-menu dropdown-menu-xl dropdown-menu-end"
                style={{ height: "fit-content" }}
              >
                <div class="dropdown-head">
                  <span class="sub-title nk-dropdown-title">Tickets</span>
                  <span
                    type="button"
                    className="btn btn-sm btn-outline-primary border-0"
                    onClick={updateNotificationStatus}
                  >
                    <em class="icon ni ni-repeat-v"></em>
                  </span>
                </div>
                <div class="dropdown-body">
                  <div class="nk-notification" style={{ minHeight: "300px" }}>
                    {/* Dropdown items */}
                    {notifications.length !== 0 ? (
                      notifications.map((n, i) => {
                        return (
                          <div
                            key={i}
                            class="nk-notification-item dropdown-inner"
                            style={{
                              cursor: "pointer",
                              backgroundColor: n?.is_read ? "#fff" : "#bddfff",
                              position: "relative",
                            }}
                            onClick={() => handleNotificationStatus(n.id)}
                          >
                            <div class="nk-notification-content">
                              <div
                                class="nk-notification-text"
                                style={{
                                  fontSize: "1.1em",
                                  fontWeight: "bolder",
                                }}
                              >
                                {n.title}
                              </div>
                              <div class="nk-notification-text">
                                {n.message}
                              </div>
                              <div class="nk-notification-time">
                                {moment(new Date(n.created_at)).format(
                                  "Do MMMM YYYY"
                                )}
                              </div>
                            </div>
                            {!n?.is_read && (
                              <div
                                style={{
                                  height: "6px",
                                  width: "6px",
                                  borderRadius: "50%",
                                  backgroundColor: "#036ffc",
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                }}
                              ></div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div class="nk-notification-item dropdown-inner">
                          <div class="nk-notification-content">
                            <div class="nk-notification-text text-secondary">
                              Empty
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div class="dropdown-foot center">
                  <Link to="/tickets">View All</Link>
                </div>
              </div>
            </div>
            {/* ) : (
                            ""
                        )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeMessage;
