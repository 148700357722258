import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { VARIABLES } from "../../../Constant";
import secureLocalStorage from "react-secure-storage";
import { JobOpeningContext } from "../../../Context/JobOpeningContext";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";

const PageOpening = (props) => {
  const [firstColumnHeight, setFirstColumnHeight] = useState(0);
  console.log(firstColumnHeight);
  const secondColumnRef = useRef(null);
  const { rawJobPosting } = useContext(JobOpeningContext);
  const { setActiveTab } = useContext(UserDetailsContext)

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [submitMessage, setSubmitMessage] = useState(null);

  const pathname = location.pathname;
  console.log(pathname);

  console.log(location.state?.jobdata);

  const JobID =
    location.state && location.state.jobdata ? location.state.jobdata?.id : "";
  console.log(JobID);
  const userID = secureLocalStorage.getItem("userID");

  const onChangeMenu = (jobdata) => {
    console.log("onchangehit");
    navigate(`/apply/${jobdata?.url_required}`, {
      state: { jobdata: jobdata },
    });
  };

  const onChangeDescription = (jobdata) => {
    navigate(`/${jobdata?.url_required}`, {
      state: { jobdata: jobdata },
    });
  };

  const onSubmit = () => {
    console.log(JobID);
    var candidate_info = JSON.stringify({
      job_opening_id: JobID,
      user_id: userID,
      notice_period: null,
      current_previous_company: "",
      expected_salary: null,
      experience: null,
      is_draft: true,
      created_by_id: userID,
      updated_by_id: userID,
    });

    console.log(candidate_info);

    const url = VARIABLES.url + "/api/candidatejob/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };
    console.log(candidate_info);

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data.data));
        console.log(JSON.stringify(response.data.data.candidate_data?.id));
        secureLocalStorage.setItem(
          "candidate_info",
          response.data.data.candidate_data?.id
        );
        setActiveTab(1)
        setSubmitMessage({
          type: "success",
          icon: "check",
          message:
            "Thankyou for choosing the Job, Please complete your Process to apply for the Job",
        });
        onChangeMenu(location.state.jobdata);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to apply for Job. Please check your network",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    // Measure the height of the first column and set it to the second column
    const firstColumnElement = document.querySelector(".col-xl-8");
    if (firstColumnElement) {
      setFirstColumnHeight(firstColumnElement.clientHeight);
    }
  }, [firstColumnHeight]);

  return (
    <>
      <Helmet>
        <title>{location.state?.jobdata?.seo_title || ""}</title>
        <meta
          name="description"
          content={location.state?.jobdata?.seo_meta_key || ""}
        />
        <meta
          name="Keywords"
          content={location.state?.jobdata?.seo_keywords || ""}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="container mt-3 pb-5">
        <div className="row justify-content-center">
          <div id="col-one" className="col-xl-8 col-md-8">
            <div className="card card-profile">
              <div className="card-body pt-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center">
                      <h2 className="h2">
                        {location.state?.jobdata?.job_title}
                      </h2>
                      <div className="h5 font-weight-300">
                        <i className="fas fa-map-marker-alt mr-2"></i>
                        {location.state?.jobdata?.location}
                      </div>
                    </div>
                    <div
                      className="card-profile-stats d-flex justify-content-center"
                      style={{
                        borderTop: "1px dashed #ccc",
                        borderBottom: "1px dashed #ccc",
                      }}
                    >
                      <div style={{ borderRight: "1px dashed #ccc" }}>
                        <span className="heading">1</span>
                        <span className="description">Vacancy</span>
                      </div>
                      <div>
                        <span className="heading">
                          {location.state?.jobdata?.experience}
                        </span>
                        <span className="description">Experience</span>
                      </div>
                    </div>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <strong>
                        <u>{location.state?.jobdata?.job_title}</u>
                      </strong>
                    </p>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>

                    <p>
                      <strong>Job Description:</strong>
                    </p>
                    <div
                      style={{ marginLeft: "35px" }}
                      id="jobRequirementsContainer"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: location.state?.jobdata?.job_description,
                        }}
                      />
                    </div>
                    <br />
                    <br />

                    <p>
                      <strong>Job Requirement:</strong>
                    </p>
                    <div id="jobRequirementsContainer">
                      <div
                        style={{ marginLeft: "35px" }}
                        dangerouslySetInnerHTML={{
                          __html: location.state?.jobdata?.job_requirement,
                        }}
                      />
                    </div>
                    <p>
                      <strong>&nbsp;</strong>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div
                  style={{ borderTop: "1px dashed #ccc" }}
                  className="d-flex justify-content-end pt-3 pe-4 mt-3"
                >
                  {isLoading ? (
                    <button className="btn text-dark" disabled>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-info"
                      id="submit-button-pageopening"
                      onClick={() => onSubmit()}
                    >
                      Apply
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-4 col-md-4"
            style={{ height: firstColumnHeight, overflow: "hidden" }}
            ref={secondColumnRef}
          >
            <div
              className=""
              style={{
                height: "100%",
                background: "transparent",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="card card-profile"
                style={{
                  overflow: "hidden",
                  height: "inherit",
                  maxHeight: "inherit",
                  position: "relative",
                }}
              >
                <div className="card-body pt-4">
                  <div className="text-center">
                    <h2>Other Openings</h2>
                  </div>

                  <div
                    className="card-profile-stats justify-content-center"
                    style={{ borderTop: "1px dashed #ccc" }}
                  >
                    {rawJobPosting?.map((jobopen, index) => (
                      <div key={index} className="col-12">
                        <a
                          href="javacsript:void(0)"
                          onClick={() => {
                            onChangeDescription(jobopen);
                            secureLocalStorage.setItem("active_tab", 1);
                          }}
                          style={{ color: "#00A0E3" }}
                          className="title"
                        >
                          {jobopen?.job_title || "-"}
                        </a>
                        <p className="description">{jobopen?.location}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div
                  className="row pt-3"
                  style={{ borderTop: "1px dashed #ccc" }}
                > */}
                <div
                  className="d-flex justify-content-center me-3 view-all-btn"
                  id="button-button-jobopening"
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "10px",
                  }}
                >
                  <Link to="/jobopening" className="btn btn-info">
                    View all Opening
                  </Link>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageOpening;
