import React from "react";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
  return (
    // {/* <!-- sidebar @s --> */}
    <div
      className="nk-sidebar is-light nk-sidebar-fixed is-light "
      data-content="sidebarMenu"
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <a
            href="https://career.fluidscapes.in/"
            target="_blank"
            className="logo-link"
          >
            <img
              className="logo-light logo-img logo-img-xl"
              src="./assets/images/fsc_animated_logo_black.gif"
              srcSet="./assets/images/fsc_animated_logo_black.gif"
              alt="logo"
            />
            <img
              className="logo-dark logo-img logo-img-xl"
              src="./assets/images/fsc_animated_logo_black.gif"
              srcSet="./assets/images/fsc_animated_logo_black.gif"
              alt="logo-dark"
            />
          </a>
        </div>
        <div className="nk-menu-trigger me-n2">
          <a
            href="#"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </a>
        </div>
      </div>
      {/* <!-- .nk-sidebar-element --> */}
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              {/* <!-- .nk-menu-item --> */}
              <li className="nk-menu-item">
                <NavLink to="/" className="nk-menu-link" id="sidebar-dashboard">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-presentation"></em>
                  </span>
                  <span className="nk-menu-text">Dashboard</span>
                </NavLink>
              </li>

              <li className="nk-menu-item">
                <NavLink
                  to="/jobopening"
                  className="nk-menu-link"
                  id="sidebar-jobopening"
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-cc-alt2"></em>
                  </span>
                  <span className="nk-menu-text">Job Opening</span>
                </NavLink>
              </li>
              {/* 
              <li className="nk-menu-item">
                <NavLink
                  to="/candidatecabinet"
                  className="nk-menu-link"
                  id="sidebar-candidatecabinet"
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-cc-alt2"></em>
                  </span>
                  <span className="nk-menu-text">Candidate Cabinet</span>
                </NavLink>
              </li> */}
              {/* WORKFLOW */}
              {/* <li className="nk-menu-item">
                <NavLink to="/hrinterview" className="nk-menu-link" id="sidebar-hrinterview">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">HR Interview</span>
                </NavLink>
              </li>
              <li className="nk-menu-item">
                <NavLink to="/assignment" className="nk-menu-link" id="sidebar-assignment">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">Assignment</span>
                </NavLink>
              </li>
              <li className="nk-menu-item">
                <NavLink to="/mettletest" className="nk-menu-link" id="sidebar-mettletest">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">Mettle Test</span>
                </NavLink>
              </li>
              <li className="nk-menu-item">
                <NavLink to="/mdapproval" className="nk-menu-link" id="sidebar-mdapproval">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">MD Approval</span>
                </NavLink>
              </li>
              <li className="nk-menu-item">
                <NavLink to="/offerattach" className="nk-menu-link" id="sidebar-offerattach">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">Offer Attach</span>
                </NavLink>
              </li> */}
              {/* <li className="nk-menu-item">
                <NavLink to="/workflow" className="nk-menu-link" id="sidebar-workflow">
                  <span className="nk-menu-icon"><em className="icon ni ni-cc-alt2"></em></span>
                  <span className="nk-menu-text">Workflow</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
