import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { VARIABLES } from '../../Constant';
import secureLocalStorage from 'react-secure-storage';
import WelcomeMessage from './WelcomeMessage';
import { googleLogout } from "@react-oauth/google";

const Header = (props) => {
  const username = secureLocalStorage.getItem('username');
  const email = secureLocalStorage.getItem('email');
  const first_name = secureLocalStorage.getItem('firstName');
  const last_name = secureLocalStorage.getItem('lastName');

  const navigate = useNavigate();

  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    navigate("/");
  };

  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            {/* <div className='nk-header-menu'>
              <a className="btn btn-info d-sm-none d-xl-block" id='header-button-back'
                onClick={() => navigate(-1)}
              >
                <span className='d-flex justify-center'><em className="icon ni ni-arrow-left" ></em><span >Back</span></span>
              </a>
            </div> */}
            <div className="nk-menu-trigger d-xl-none ms-n1 me-3">
              <a href="#" className="nk-nav-toggle nk-quick-nav-icon" id="button-button-sidebar_open" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href={VARIABLES.fsc_redirection} target="_blank" className="logo-link" id="logo-fluidscapes">
                <img className="logo-light logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo" />
                <img className="logo-dark logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo-dark" />
              </a>

            </div>
            {/* <!-- .nk-header-brand --> */}
            {/* <!-- .nk-header-menu --> */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                {/* <li style={{listStyle: "none"}}>
                  <a className="btn btn-info d-xl-none" id='header-button-back'
                    onClick={() => navigate(-1)}>
                      <em class="icon ni ni-arrow-left"></em><span className='d-none d-md-block'>Back</span>
                  </a>
                </li> */}
                <li className="dropdown user-dropdown" id="button-button-dropdown">
                  <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>{first_name?.charAt(0) + last_name?.charAt(0)}</span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{first_name} {last_name}</span>
                          <span className="sub-text">{email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list" style={{ listStyle: "none" }}>
                        {/* <li>
                          <Link to="/profile"><em className="icon ni ni-user"></em><span>View Profile</span></Link>
                        </li> */}
                        <li id="button-button-signout"><a href="javascript:void(0)" onClick={handleLogout}>
                          <em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- .nk-header-tools --> */}
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
      <WelcomeMessage />
    </>
  )
};

export default Header;