import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../Context/DashboardContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { VARIABLES } from "../../../Constant";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";

const PendingApplication = (props) => {
  const {
    pendingData,
    setPendingEntries,
    pendingPageCount,
    pendingEntries,
    pendingEntryCount,
    handlePendingDataPageClick,
    pendingDataLoading,
    getPendingData,
  } = useContext(DashboardContext);

  const { setActiveTab, getProfessionalDetails } = useContext(UserDetailsContext)

  console.log("pendingData:", pendingData);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const navigate = useNavigate();

  const handleEntries = (event) => {
    setPendingEntries(event.target.value);
    getPendingData(1, userID);
  };

  const onChangeMenu = (jobdata) => {
    console.log(jobdata);
    navigate(`/apply/${jobdata.job_opening.url_required}`, {
      state: { jobdata: jobdata.job_opening, candidateJobData: jobdata },
    });
  };

  const onSubmit = (postData) => {
    console.log("-----", postData);
    var candidate_info = JSON.stringify({
      job_opening_id: postData?.job_opening?.id,
      user_id: userID,
      // notice_period: null,
      // current_previous_company: "",
      // expected_salary: null,
      // experience: null,
      is_draft: true,
      updated_by_id: userID,
    });

    console.log(candidate_info);

    const url =
      VARIABLES.url + `/api/candidatetobeprocess/${postData?.id}/`;
    console.log(url);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };
    console.log(candidate_info);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        console.log(JSON.stringify(response.data.id));
        console.log("id=", postData.id);
        secureLocalStorage.setItem("candidate_info", postData.id);
        props.setSubmitMessage({
          type: "success",
          icon: "check",
          message:
            "Thankyou for choosing the Job, Please complete your Process to apply for the Job",
        });
        onChangeMenu(postData);
        getProfessionalDetails()
        setActiveTab(1)
      })
      .catch((error) => {
        console.log(error);
        props.setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to apply for Job. Please check your network",
        });
      });
  };

  useEffect(() => {
    getPendingData(1, userID);
  }, []);

  return (
    <>
      <div class="card">
        <div class="card-inner-group">
          <div className="card-inner">
            <h6 class="h6">Pending Applications</h6>
          </div>
          {pendingDataLoading ? (
            <div className="mt-2">
              <div className="card-inner"> loading... </div>
            </div>
          ) : (
            <div class="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  {pendingData && pendingData?.data?.length === 0 && (
                    <div className="m-2">No data found</div>
                  )}
                  {/* {pendingData.data.length === 0 && <div>No data found</div>} */}
                  {pendingData.data?.length > 0 && (
                    <div class="nk-tb-list">
                      <div class="nk-tb-item nk-tb-head">
                        <div class="nk-tb-col tb-col-sm">
                          <span>Job Title</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Current/Previous Company</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Excepted Salary</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>Experience</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>Notice Period</span>
                        </div>
                        <div class="nk-tb-col tb-col-md">
                          <span>Resume</span>
                        </div>

                        {/* <!-- <div class="nk-tb-col tb-col-md"><span>Accept/ Reject</span></div> --> */}
                        <div class="nk-tb-col tb-col-md">
                          <span>Apply</span>
                        </div>
                      </div>
                      {pendingData.data.map((post, index) => (
                        <div class="nk-tb-item" key={index}>
                          <div class="nk-tb-col tb-col-sm">
                            <span class="tb-product">
                              <span class="title">
                                {post?.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              {post?.current_previous_company || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-lead">
                              {post?.expected_salary || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              {post?.experience || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              {post?.notice_period || "-"}
                            </span>
                          </div>
                          <div class="nk-tb-col">
                            {post?.user_details?.attachment ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.user_details?.attachment}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div class="nk-tb-col">
                            <span class="tb-sub">
                              <a
                                onClick={() => onSubmit(post)}
                                style={{ cursor: "pointer" }}
                                id="button-button-applyjob"
                              >
                                <em className="icon ni ni-eye"></em>
                                <span>Apply</span>
                              </a>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </table>
              </div>
            </div>
          )}
          <div className="card-inner">
            <div className="nk-block-between-md g-3">
              <div className="g">
                <ReactPaginate
                  key={pendingPageCount}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pendingPageCount}
                  marginPagesDisplayed={2}
                  // pageRangeDisplayed={3}
                  onPageChange={(data) =>
                    handlePendingDataPageClick(data, userID)
                  }
                  containerClassName={
                    "pagination justify-content-center justify-content-md-start"
                  }
                  pageClassName={"paginate_button page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"paginate_button page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
              <div className="g">
                <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                  <div>
                    Total entries:&nbsp;
                    <span className="fw-bold">
                      {pendingEntryCount === null ? 0 : pendingEntryCount}
                    </span>
                  </div>
                  <div>No of entries</div>
                  <div>
                    <select
                      className="form-select js-select2"
                      data-search="on"
                      data-dropdown="xs center"
                      value={pendingEntries}
                      onChange={handleEntries}
                    >
                      <option value="5">5</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingApplication;
