import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "./RefreshTokenContext";
export const TypemasterDropdownContext = createContext(null);

function TypemasterDropdownContextProvider({ children }) {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const [isDropdownDataLoading, setIsDropdownDataLoading] = useState(true);
  console.log(isDropdownDataLoading);
  const [parentCategory, setParentCategory] = useState(null);

  //DROPDOWN STATES
  const [jobTypeDropdownData, setJobTypeDropdownData] = useState([]);
  const [priorityDropdownData, setPriorityDropdownData] = useState([]);
  const [interviewTypeDropdownData, setInterviewTypeDropdownData] = useState(
    []
  );
  const [interviewModeDropdownData, setInterviewModeDropdownData] = useState(
    []
  );
  const [interviewCategoryDropdownData, setInterviewCategoryDropdownData] =
    useState([]);
  const [agencyTypeDropdownData, setAgencyTypeDropdownData] = useState([]);
  const [parentValueDropdownData, setParentValueDropdownData] = useState([]);
  const [interviewActionDropdownData, setInterviewActionDropdownData] =
    useState([]);
  const [RE2PortalsDropdownData, setRE2PortalsDropdownData] = useState([]);
  const [RECollegesDropdownData, setRECollegesDropdownData] = useState([]);
  const [KYCDocuments, setKYCDocuments] = useState([]);
  const [requisitionID, setRequisitionID] = useState(null);
  const [requisitionReasonDropdownData, setRequisitionReasonDropdownData] =
    useState([]);
  const [jobLocationDropdownData, setJobLocationDropdownData] = useState([]);

  let dropdownData;
  const getDropdownData = async (access) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/typemasterdetails/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      dropdownData = res.data.data;
      setJobTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "job_type")
      );
      setPriorityDropdownData(
        dropdownData?.filter((post) => post.category_name == "priority")
      );
      setInterviewTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview_type")
      );
      setInterviewModeDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview_mode")
      );
      setInterviewCategoryDropdownData(
        dropdownData?.filter((post) => post.category_name == "category")
      );
      setAgencyTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "agency_type")
      );
      setInterviewActionDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview-action")
      );
      setRE2PortalsDropdownData(
        dropdownData?.filter((post) => post.category_name == "re2_portals")
      );
      setRequisitionID(
        dropdownData?.filter((post) => post.category_name == "requisition_id")
      );
      setRECollegesDropdownData(
        dropdownData?.filter((post) => post.category_name == "re1_college")
      );
      setKYCDocuments(
        dropdownData?.filter((post) => post.category_name == "document_name")
      );
      setRequisitionReasonDropdownData(
        dropdownData?.filter(
          (post) => post.category_name == "requisition_reason"
        )
      );
      setJobLocationDropdownData(
        dropdownData?.filter((post) => post.category_name == "job_location")
      );
      setIsDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getDropdownData(access);
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getDropdownData(access);
    }
  }, [isAccess]);

  useEffect(() => {
    console.log("parentCategory: ", parentCategory);
    console.log("parentCategory: ", parentCategory);
    setParentValueDropdownData(
      dropdownData?.filter((post) => post.category_name == { parentCategory })
    );
  }, [parentCategory]);

  const value = {};
  return (
    <div>
      <TypemasterDropdownContext.Provider
        value={{
          isDropdownDataLoading,
          setParentCategory,

          jobTypeDropdownData,
          priorityDropdownData,
          interviewTypeDropdownData,
          interviewModeDropdownData,
          interviewCategoryDropdownData,
          agencyTypeDropdownData,
          parentValueDropdownData,
          interviewActionDropdownData,
          RE2PortalsDropdownData,
          RECollegesDropdownData,
          KYCDocuments,
          requisitionID,
          requisitionReasonDropdownData,
          jobLocationDropdownData,
        }}
      >
        {children}
      </TypemasterDropdownContext.Provider>
    </div>
  );
}

export default TypemasterDropdownContextProvider;
