import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { VARIABLES } from "../../../Constant";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [confirmPassToggle, setConfirmPassToggle] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const { id } = useParams();

  const forgotpassword_uid = secureLocalStorage.getItem('forgotpassword_uid');
  const forgotpassword_token = secureLocalStorage.getItem('forgotpassword_token');

  const onSubmit = (data) => {
    var user = JSON.stringify({
      password: data?.password,
      password2: data?.confirm_pwd,
    });

    console.log(user);

    const url = VARIABLES.url + `/api/career-reset-password/${forgotpassword_uid}/${forgotpassword_token}/`;
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    console.log(user);
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsLoading(false);
          console.log(JSON.stringify(response.data.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message:
              "Password changed successfully.",
          });
          setTimeout(() => {
            navigate('/')
          }, 2000)

        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error.response.data.message,
        });
      });
  };

  const validatePasswordMatch = (value) => {
    console.log(value);
    const password = watch("password");
    console.log(password);
    return value === password || "Passwords does not match";
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);


  return (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="card p-3" style={{ maxWidth: "600px" }}>
        <div className="card-header">
          <div className="card-title">
            <h4>Change Password</h4>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    {/* <input
                      type="password"
                      className="form-control"
                      id="password-form-password"
                      placeholder="Enter Password"
                      {...register("password", { required: true })}
                    /> */}

                    <input
                      type={`${passwordToggle === true ? "text" : "password"} `}
                      className="form-control"
                      id="password-form-password"
                      placeholder="Enter Password"
                      {...register("password", { required: true })}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setPasswordToggle(!passwordToggle)}
                      id="basic-addon2"
                    >
                      <i
                        className={`${passwordToggle === true
                          ? "icon ni ni-eye"
                          : "icon ni ni-eye-off"
                          } `}
                      ></i>
                    </span>
                  </div>
                  <div className="d-flex flex-column p-1">
                    <small>
                      Your password can't be too similar to your other personal
                      information.
                    </small>
                    <small>
                      Your password must contain at least 8 characters.
                    </small>
                    <small>
                      Your password can't be a commonly used password.
                    </small>
                    <small>Your password can't be entirely numeric.</small>
                  </div>
                  {errors.password && (
                    <span className="fs-8 text-danger">
                      Password is required
                    </span>
                  )}
                  {watch("password") && watch("password").length < 8 && (
                    <span className="fs-8 text-danger">
                      Password must be at least 8 characters
                    </span>
                  )}
                  {watch("password") &&
                    watch("password").length > 8 &&
                    /^\d+$/.test(watch("password")) && (
                      <span className="fs-8 text-danger">
                        Password must be alpha-numeric
                      </span>
                    )}
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={`${confirmPassToggle === true ? "text" : "password"
                        } `}
                      className="form-control"
                      id="password-form-confirm_pwd"
                      placeholder="Enter Password"
                      {...register("confirm_pwd", {
                        required: true,
                        validate: validatePasswordMatch,
                      })}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setConfirmPassToggle(!confirmPassToggle)}
                      id="basic-addon2"
                    >
                      <i
                        className={`${confirmPassToggle === true
                          ? "icon ni ni-eye"
                          : "icon ni ni-eye-off"
                          } `}
                      ></i>
                    </span>
                  </div>
                  {errors.confirm_pwd?.message ? (
                    <span className="fs-8 text-danger">
                      {errors.confirm_pwd.message ||
                        "Confirm Password is required"}
                    </span>
                  ) : (
                    errors.confirm_pwd && (
                      <span className="fs-8 text-danger">
                        Confirm Password is required
                      </span>
                    )
                  )}
                </div>
                <div className="form-group d-flex justify-content-center">
                  {isLoading === true ? (
                    <div>Loading...</div>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary btn-block"
                      id="submit-button-register"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div
            className="example-alerts position-fixed bottom-0 end-0 p-3"
            style={{ zIndex: 100 }}
          >
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
