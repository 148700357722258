export const VARIABLES = {
  // for dev
  // url: "http://64.226.76.196:8020",
  // career_login : "car-vqev"

  // for testing
  // url: "http://127.0.0.1:8011",
  url: "https://rms-api-test.techwalnut.co.in",
  // url: "https://rms-api.fluidscapes.in",
  career_login: "car-mude",
  career_redirection: "https://career-page-test.techwalnut.co.in/",
  // career_redirection : "http://localhost:3001/",
  fsc_redirection: "https://fluidscapes.in/"
};
